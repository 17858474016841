import React from 'react';
import mb from '../assets/Mobile-App-Development-Banner-Final.png';
import wb from '../assets/web-development-image.png';
import pl from '../assets/download.jpeg';

export default function About() {
  return (
    <div className=" mt-7">
      <div className="flex flex-col sm:flex-row">
        <div className="flex-auto font-lato p-20 pt-0" style={{ flex: 1 }}>
          <p className="font-bold text-xl mb-5">About me</p>
          <div>
            <span className="">
              I am a passionate and dedicated Mobile App and Web Developer currently pursuing a
            </span>
            <span className="text-violet-950 text-base font-lato font-bold leading-normal">
              Bachelor's degree in Computer Science and Information Technology
            </span>
            <span className=""> </span>
            <span className="text-blue-950 text-base font-normal font-lato leading-normal">
              at Prithvi Narayan Campus in Pokhara, Nepal.
            </span>
          </div>
        </div>
        <div className="flex-auto font-lato p-20 pt-0" style={{ flex: 1 }}>
          <p className="font-bold text-xl mb-5">My Journey</p>
          <div>
            My journey in the world of technology began with a deep curiosity and a desire to
            create innovative solutions that can make a positive impact on people's lives. Over the
            years, I have honed my skills in both mobile app and web development, allowing me to
            work on a wide range of projects and gain valuable experience.
          </div>
        </div>
      </div>
      <div className="font-bold text-xl font-lato p-20 pt-0 pb-5">My Expertise</div>
      <div className="flex flex-col sm:flex-row font-lato p-20 pt-0 gap-14">
        <a href="www.github.com/sandeshchapagai" className="hover:scale-110 transform transition-transform">
          <div className="max-w-sm rounded overflow-hidden shadow-lg enlarge-on-hover">
            <img className="w-full h-[173px]" src={mb} alt="Mobile App Development" />
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2">Mobile App Development</div>
              <p className="text-gray-700 text-base">
                I specialize in creating mobile applications for both Android and iOS platforms. My
                expertise includes building user-friendly and interactive apps that cater to the
                needs of modern users.
              </p>
            </div>
          </div>
        </a>
        <a href="www.github.com" className="hover:scale-110 transform transition-transform">
          <div className="max-w-sm rounded overflow-hidden shadow-lg">
            <img className="w-full h-[173px]" src={wb} alt="Web Development" />
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2">Web Development</div>
              <p className="text-gray-700 text-base">
                I am well-versed in web development technologies, including front-end development. I
                create responsive and visually appealing websites with a focus on user experience.
              </p>
            </div>
          </div>
        </a>
        <a href="www.github.com.sandeshchapagai" className="hover:scale-110 transform transition-transform">
          <div className="max-w-sm rounded overflow-hidden shadow-lg">
            <img className="w-full h-[173px]" src={pl} alt="Passion for Learning" />
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2">Passion For Learning</div>
              <p className="text-gray-700 text-base">
                I am committed to staying up-to-date with the latest trends and technologies in the
                field of technology. Continuous learning and improvement are essential to me.
              </p>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}
