import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/full_logo.png'
export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="">
      <nav className="bg-transparent">
        <div className="flex flex-wrap items-center justify-between ">
          <span className="self-center flex items-center font-leckerli md:text-6xl font-semibold whitespace-nowrap dark:text-black">
          <img  className='w-40  h-20' src={logo} alt="sandeshchapagai"  />
          </span>
          <button
            onClick={toggleMenu}
            data-collapse-toggle="navbar-default"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-default"
            aria-expanded={isMenuOpen}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div className={`w-full md:block md:w-auto font-ubuntu-condensed mx-auto ${isMenuOpen ? '' : 'hidden'}`} id="navbar-default">
            <ul className="font-medium bg-red-50 flex flex-col p-4 md:p-0 mt-4 md:flex-row md:space-x-20 md:mt-0 md:border-0 md:bg-transparent">
              <li>
                <NavLink to="/"
                  className="block py-2 pl-3 pr-4 md:bg-transparent md:text-black md:p-0 dark:text-black md:dark:text-black"
                  aria-current="page" >
                  HOME
                </NavLink>
              </li>
              <li>
                <NavLink to="/about"
                  className="block py-2 pl-3 pr-4 md:bg-transparent md:text-black md:p-0 dark:text-black md:dark:text-black"
                  aria-current="page">
                
                  ABOUT
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/services"
                  className="block py-2 pl-3 pr-4 bg-transparent text-black bg-rounded md:bg-transparent md:text-black md:p-0 dark:text-black md:dark:text-black"
                  aria-current="page"
                >
                  PROJECTS
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contact"                                                                                        
                  className="block py-2 pl-3 pr-4 bg-transparent text-black bg-rounded md:bg-transparent md:text-black md:p-0 dark:text-black md:dark:text-black"
                  aria-current="page" >
                  CONTACT
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/resume"
                  className="block py-2 pl-3 pr-4 bg-transparent text-black bg-rounded md:bg-transparent md:text-black md:p-0 dark:text-black md:dark:text-black"
                  aria-current="page"
                >
                  RESUME
                </NavLink>
              </li>
              {/* Rest of your navigation items */}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}