import React, { useState } from 'react';
import email from '../assets/email.png';
import phone from '../assets/telephone.png';
import location from '../assets/location.png';
import Linkedin from '../assets/linkedin.png';
import instagram from '../assets/instagram.png';
import facebook from '../assets/facebook.png';
import twitter from '../assets/twitter.png';
import github from '../assets/github.png';
import message from '../assets/send.png'
const TextInput = ({ placeholder }) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div className="py-10 font-lato"> {/* Add margin bottom for gap */}
    <input
      className={`w-[550px] py-2  border-b-2 ${
        isFocused ? 'border-violet-600 text-black' : 'border-gray-400 text-gray-500'
      } bg-transparent focus:outline-none`}
      type="text"
      placeholder={placeholder}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  </div>
  );
};

export default function Contact() {
  return (
    <div className="flex font-lato flex-col sm:flex-row ">
      {/* 1st part */}
      <div class="flex-auto pl-20 pt-10 pb-10 font-lato">
        <div className="w-[661px] h-[179px]">
          <span className="text-black text-5xl font-bold ">Let’s discuss <br/>on something </span>
          <span className="text-violet-950 text-5xl font-bold ">cool </span>
          <br />
          <span className="text-black text-5xl font-bold ">together</span>
        </div>

        {/* div for email and phone number */}
        <div className="">
          <div className='pl-7'>
            <div className='pl-10 pb-6'>
              <span style={{ display: 'inline-block', marginRight: '10px' }}>
                <img src={email} alt="email" />
              </span>
              <span className="text-black text-2xl display: 'inline-block' font-bold ">
                  sandeshchapagai15@gmail.com
              </span>
              <br />
            </div>

            <div className='p-6 pl-10'>
              <span style={{ display: 'inline-block', marginRight: '10px' }}>
                <img src={phone} alt="email" />
              </span>
              <span className="text-black text-2xl display: 'inline-block' font-bold ">
                +977986754321
              </span>
              <br />
            </div>

            <div className='p-6 pl-10'>
              <span style={{ display: 'inline-block', marginRight: '10px' }}>
                <img src={location} alt="email" />
              </span>
              <span className="text-black text-2xl display: 'inline-block' font-bold ">
                Baglung, Nepal
              </span>
              <br />
            </div>
          </div>
        </div>

          <div className="flex space-x-8 pt-20 pr-10 pl-5 md:pb-0 pb-10 md:pl-[70px]">
  <a href="https://www.linkedin.com/sandesh56" target="_blank" rel="noreferrer" className="social-icon">
    <img src={Linkedin} alt="LinkedIn" className="w-6 h-6 object-cover transform transition-transform hover:scale-150" />
  </a>
  <a href="https://www.instagram.com/sandesh.56" target="_blank" rel="noreferrer" className="social-icon">
    <img src={instagram} alt="Instagram" className="w-6 h-6 object-cover transform transition-transform hover:scale-150" />
  </a>
  <a href="https://github.com/sandeshchapagai" target="_blank" rel="noreferrer" className="social-icon">
    <img src={github} alt="GitHub" className="w-6 h-6 object-cover transform transition-transform hover:scale-150" />
  </a>
  <a href="https://www.facebook.com/sandesh.567890" target="_blank" rel="noreferrer" className="social-icon">
    <img src={facebook} alt="Facebook" className="w-6 h-6 object-cover transform transition-transform hover:scale-150" />
  </a>
  <a href="https://twitter.com/SanDesh_56" target="_blank" rel="noreferrer" className="social-icon">
    <img src={twitter} alt="Twitter" className="w-6 h-6 object-cover transform transition-transform hover:scale-150" />
  </a>



        </div>
      </div>

      {/* Second part */}
      <div className="flex-auto">
        <div className=''>
        <TextInput className='py-10'
        placeholder='Your Name ' />
        <TextInput 
        placeholder='Your Email'
        />
          <TextInput 
        placeholder='Your Message'
        />
        
        <div className='mt-20 pt-[60px]'>
        <button className="bg-violet-950 text-white font-bold text-xl font-lato py-2 px-4 rounded-2xl">
  <img style={{ marginRight: '10px', verticalAlign: 'middle', display:'inline-block' }} src={message} alt="msg" />
  Send Message
</button>
    
        </div>
        </div>

      </div>
    </div>
  );
}
