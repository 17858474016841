import React from 'react';
import ress from '../resu.pdf'


export default function Resume() {
  return (
    <div>
      <iframe title="Resume Document" src={ress} type="application/pdf" width="100%" height="500px" />
    </div>
  );
}
