import { Routes, Route } from 'react-router-dom';
import './App.css';
import About from './components/About';
import Hero from './components/Hero';
import Navbar from './components/Navbar';
import Services from './components/Services';
import Resume from './components/Resume';
import Contact from './components/Contact';

function App() {
  return (
    <div className="bg-custom-gradient bg-cover bg-no-repeat min-h-screen ">
      <div className='px-0 md:px-20 md:pt-10'>
      <Navbar />
      <Routes>
        <Route path="/" element={<Hero />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
         <Route path="/contact" element={<Contact/>}/>   
         <Route path="/resume" element={<Resume/>}/>   
      </Routes>
      </div>
    </div>
  );
}

export default App;
