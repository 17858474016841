import React, { useState } from 'react';
import AllContent from './services/All';
import WebContent from './services/Web';
import MobilContent from './services/Mobile';

export default function Services() {
  const [activeTab, setActiveTab] = useState("ALL");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="text-xl font-lato font-bold ml-20">
      <div className="flex mt-2  justify-center">
        <button
          className={`mr-80 ${activeTab === "ALL" ? 'bg-violet-950 rounded-full w-20  text-white  ' : ''}`}
          onClick={() => handleTabClick("ALL")}
        >
          All
        </button>
        <button
          className={`mr-80 ${activeTab === "Mobile" ? 'bg-violet-950 rounded-full w-20  text-white  ' : ''}`}
          onClick={() => handleTabClick("Mobile")}
        >
          Mobile
        </button>
        <button
          className={`${activeTab === "WEBSITE" ?'bg-violet-950 rounded-full w-20  text-white  ': ''}`}
          onClick={() => handleTabClick("WEBSITE")}
        >
          Web
        </button>
      </div>
      <div className="mt-4">
        {activeTab === "ALL" && <AllContent/>}
        {activeTab === "Mobile" && <MobilContent/>}
        {activeTab === "WEBSITE" && <WebContent/>}
      </div>
    </div>
  );
}
