import React from "react";
import { TypeAnimation } from "react-type-animation";
import avatar from "../assets/avatar.png";
import Linkedin from "../assets/linkedin.png";
import instagram from "../assets/instagram.png";
import facebook from "../assets/facebook.png";
import twitter from "../assets/twitter.png";
import github from "../assets/github.png";

export default function Hero() {
  return (
    <div className="">
      <div className="font-lato flex flex-wrap flex-col-reverse md:flex-row  mx-3 md:my-10">
        <div className="md:flex-auto md:w-1/2 ">
          <p className="text-2xl md:text-4xl">I'm</p>
          <p className="text-3xl md:text-5xl font-bold">SanDesh SharMa</p>
          <TypeAnimation
            className="font-semibold:text-3xl pt-4 w-full"
            sequence={[
              "Elevating User Experiences with App development ",
              1000,
              "Elevating User Experiences with web development",
              1000,
              "Elevating User Experiences with UI/UX designer",
              1000,
            ]}
            wrapper="span"
            speed={20}
            style={{ fontSize: "2em", display: "inline-block" }}
            repeat={Infinity}
          />
          <p className="font-thin text-lg py-6 ">
            I'm a CSIT student at Prithvi Narayan Campus, Pokhara, Nepal.
            <br />
            I've worked with startups and established companies.
            <br />
            I'm passionate about my work.
          </p>
          <button className="bg-violet-950 hover:scale-110 transform transition-transform text-white font-bold md:py-4 md:px-8 px-4 py-2 rounded-2xl">
            Contact →
          </button>

          {/* <button className="bg-violet-950 text-white font-bold rounded-2xl py-4 px-8 hover:scale-110 transition-all">
            Contact →
          </button> */}
        </div>
        <div className="">
          <img
            className="md:w-[350px] w-100 h:100 pb-10"
            src={avatar}
            alt="sandesh chapagai"
          />
        </div>
      </div>
      
      <div className="flex justify-center items-center space-x-8 py-5">
            <a
              href="https://www.linkedin.com/sandesh56"
              target="_blank"
              rel="noreferrer"
              className="social-icon"
            >
              <img
                src={Linkedin}
                alt="LinkedIn"
                className="w-6 h-6 object-cover transform transition-transform hover:scale-150"
              />
            </a>
            <a
              href="https://www.instagram.com/sandesh.56"
              target="_blank"
              rel="noreferrer"
              className="social-icon"
            >
              <img
                src={instagram}
                alt="Instagram"
                className="w-6 h-6 object-cover transform transition-transform hover:scale-150"
              />
            </a>
            <a
              href="https://github.com/sandeshchapagai"
              target="_blank"
              rel="noreferrer"
              className="social-icon"
            >
              <img
                src={github}
                alt="GitHub"
                className="w-6 h-6 object-cover transform transition-transform hover:scale-150"
              />
            </a>
            <a
              href="https://www.facebook.com/sandesh.567890"
              target="_blank"
              rel="noreferrer"
              className="social-icon"
            >
              <img
                src={facebook}
                alt="Facebook"
                className="w-6 h-6 object-cover transform transition-transform hover:scale-150"
              />
            </a>
            <a
              href="https://twitter.com/SanDesh_56"
              target="_blank"
              rel="noreferrer"
              className="social-icon"
            >
              <img
                src={twitter}
                alt="Twitter"
                className="w-6 h-6 object-cover transform transition-transform hover:scale-150"
              />
            </a>
          </div>
    </div>
  );
}
